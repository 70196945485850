import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import Style from './ExamDetails.module.css';


const ExamDetails = () => {
    const notify = (mess, type) => {
        toast[type](mess);
      };
    
      let navigate =useNavigate()

    const [examDetails, setExamDetails] = useState(null);
    const [loading, setLoading] = useState(false);
   
    let {quizid}= useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/quizzes/${quizid}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('userToken')}`
                    }
                });
                setLoading(false);
                setExamDetails(response?.data?.data);
            }    catch (error) {
                if (error.response.status === 405) {
                    setLoading(false)
                  navigate('/login') ;
                   notify(error.response.data.message, "success");
            
                }
                setLoading(false)
            
                notify(error.response.data.message, "success");
            
                console.error('Error fetching profile data:', error);
              }
        };
        fetchData()

    }, [quizid]);


   
    return <>

         
                {loading?<>
    <div className={`${Style.home2} w-full flex justify-center`}>
            <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />
          </div>
          </>:
          <>
          <div className={`${Style.home} bg-gray-100 min-h-screen rounded-tr-3xl`}>
          <div className="flex items-center max-sm:justify-center bg-white p-5 rounded-xl my-5">
      <Link to={'/My_exams'} className="mr-2 text-maingreen font-bold"> الرجوع الي الأختبارات المحلولة </Link>
    </div> 
       
                <div className="bg-white p-5 rounded-xl my-5 flex justify-between">
                    <div></div>
                    <p to={'/Subject'} className='font-bold text-progress_text mb-2'>{examDetails?.quizTitle}</p>
                    <p to={'/Subject'} className='text-customGreen '>{examDetails?.questions.length} سؤال</p>
                </div>
                {examDetails?.questions.map((question) => (
                    <div key={question?.id} className={question?.correctAnswer === question?.studentAnswer ? 'bg-white p-10 rounded-2xl border-green-600 mt-3 border-2' : 'bg-white p-10 rounded-2xl border-red-600 mt-3 border-4'}>
                        <div className='flex flex-row items-center justify-between'>
                            <div>
                            {/* <span className='bg-customGreen rounded-full p-1 m-2'></span> */}
                            <h1 className='text-lg text-black'> <span className='text-green-600'>  السؤال :</span>  {question?.question}</h1>
                            </div>
                         
                        </div>
                        
                        <div className='grid grid-cols-3 gap-3 max-md:grid-cols-1 pt-5 justify-center items-center '>
                            <div className='grid grid-cols-2 gap-5 max-md:w-11/12 col-span-2'>
                            {Object.entries(question).map(([key, value]) => {
if (key.startsWith('answer')) {
    const isStudentAnswer = value === question.studentAnswer;
    const isCorrect = value === question.correctAnswer;
    const isIncorrect = !isCorrect && isStudentAnswer;

    return (
        <div className='' key={key}>
            <div className="block">
                <div className="text-lg font-semibold">
                    <h2 className={isCorrect ? 'bg-customGreen text-white w-fit p-3 rounded-xl' : isIncorrect ? 'bg-red-500 text-white w-fit p-3 rounded-xl' : 'bg-gray-200 w-fit p-3 rounded-xl'}>
                        {value}
                    </h2>
                </div>
            </div>
        </div>
    );
}
    return null;
})}
                            </div>
                            <div className='w-full max-lg:order-first max-lg:pb-2 '>
                            <a href={question?.imageUrl} target="_blank" rel="noopener noreferrer">
                            <img src={question?.imageUrl} className='w-8/12 h-auto rounded-lg' alt="" srcSet="" />
                            </a>
                            </div>
                        </div>
                    </div>
                ))}
             
            </div>
          </>}
            
         
            </>
          
    


          
}

export default ExamDetails;
