import React from 'react';
import { Link } from 'react-router-dom';
import Style from './SecondNavbar.module.css'
import imge from '../../images/logo512.jpg'
import logo from '../../images/logo512.jpg'

const SecondNavbar = () => {
  const user=    localStorage.getItem('user' )

    return <>
    <div className={`flex fixed top-0 left-0 w-12/12 right-0 justify-between gap-4 navbar bg-gray-50 `}>
  
  
 
  
    <div className='ps-5'>
<p  onClick={()=>document.getElementById('my_modal_2').showModal()} className=' cursor-pointer text-red-600 font-bold text-xl'>خروج</p>
  </div>

  <dialog id="my_modal_2" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg mb-4">هل انت متأكد من الخروج من الامتحان ؟</h3>
   
  <div className="modal-action">
      <form method="dialog" className='mx-auto'>
               <Link to={'/'} className="py-2.5 px-5 me-3 text-sm font-medium text-white  bg-customGreen rounded-lg border border-gray-200 hover:bg-green-700  ">
                   نعم
                    </Link>
                <button className="text-white bg-red-600 hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                    لا
                </button>
              
              </form>
    </div>
    </div>

</dialog>

  <Link to={''} className=' rounded-xl font-bold max-md:w-11/12 '	>
  <img src={logo} alt="" className="w-14 rounded-full me-2" />
  <p>دورة المنقذ  <span className='px-1 max-md:hidden text-green-600'>ITC</span></p> 
    </Link>
  
  
   
      <div className="dropdown dropdown-end max-md:w-11/12">
        <div tabIndex={0} role="button" className="btn  btn-ghost bg-transparent	border-none hover:bg-transparent">
        <div className={Style.content}>
        <div className="flex ">
    <div className="flex-none  ">
    <svg width="30" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 35C7.5 35 5 35 5 32.5C5 30 7.5 22.5 20 22.5C32.5 22.5 35 30 35 32.5C35 35 32.5 35 32.5 35H7.5ZM20 20C21.9891 20 23.8968 19.2098 25.3033 17.8033C26.7098 16.3968 27.5 14.4891 27.5 12.5C27.5 10.5109 26.7098 8.60322 25.3033 7.1967C23.8968 5.79018 21.9891 5 20 5C18.0109 5 16.1032 5.79018 14.6967 7.1967C13.2902 8.60322 12.5 10.5109 12.5 12.5C12.5 14.4891 13.2902 16.3968 14.6967 17.8033C16.1032 19.2098 18.0109 20 20 20Z" fill="#009539"/>
</svg>  
    </div>
    <div className={`grow ps-2 pt-3 max-md:pt-2    `}>
{user}    
      <span className='ps-2  max-sm:hidden'><i className="fa-solid fa-sm fa-chevron-down text-green-600"></i></span>
    </div>
  
  </div>
          
        
       
        </div>
        </div>
      
      </div>
  
     
  
  </div>
  
  
  
  
  
  
   </>
}

export default SecondNavbar;
