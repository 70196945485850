// Layout.js
import React, { useState } from 'react';
import './Layout.css';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { Outlet } from 'react-router-dom';

const Layout = () => {
    const [side, setSide] = useState(false);
  
    const toggleSide = () => {
      setSide(prevSide => !prevSide);
    };
   
   
    return (
        <>
            <Navbar toggleSide={toggleSide} />
            <div className="flex HomeComponanet">
                <div className={` ${side ? 'sideAfterPress' : 'sideBar  w-1/12'}`}>
                    <Sidebar />
                </div>
                <div className="home flex-1 w-11/12 outletAfterPress">
                    <Outlet />
                </div>
            </div>
        </>
    );
}

export default Layout;
