import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Style from './ComplaintsDetails.module.css'
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { format ,parseISO } from 'date-fns';
import { ar } from 'date-fns/locale';
import { toast } from 'react-toastify';


const ComplaintsDetails = () => {
    let {complaintId}=useParams()
    const [loading, setloading] = useState(false);
    const [formattedDate, setformattedDate] = useState();
    
    const [complaintData, setComplaintData] = useState(null);
    const notify = (mess, type) => {
        toast[type](mess);
      };
      let navigate =useNavigate()
    useEffect(() => {
        const fetchData = async () => {
          try {
            // Fetch data from the API using Axios
            setloading(true)
            const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/complaints/${complaintId}`,{
                headers:{
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`
                }
        });
            
            const data = response.data.data;
            const timestamp = '2024-03-04 09:26:57.720';
            const parsedDate = parseISO(timestamp);
            const formattedDate = format(parsedDate, "d MMMM yyyy", { locale: ar }); // Assuming 'ar' is the locale for Arabic

            setformattedDate(formattedDate)
            setloading(false)
            setComplaintData(data);
            
          } catch (error) {
            if (error.response.status === 405) {
                setloading(false)
                navigate('/login') ;
                 notify(error.response.data.message, "success");
          
              }
            setloading(false)
            // Handle errors, such as network errors or API errors
            console.error('Error fetching data:', error);
          }
        };
    
        // Call the fetchData function when the component mounts
        fetchData();
      }, []);
    return <>
    <Helmet>
    <title>الشكوي</title>
    </Helmet>

    {loading?<>
        <div className={`${Style.home2} w-full flex justify-center`}>
            <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />
          </div>

    </>:<>
    <div className={`${Style.home} bg-gray-100 w-full rounded-xl min-h-screen  `}>
    <div className="  flex items-center bg-white p-5 rounded-xl my-5">
<div className="flex items-center">
        <Link to={'/My_Complaints'} className="mr-2 text-green-500">قائمة الشكاوي</Link>
    </div>   
     <div className="flex items-end pt-1">
        <span className='px-2'>
            <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
            </svg>
        </span>
    </div>
    <Link to={'/My_Complaints/ComplaintsDetails/1'} className='font-bold text-green-600'>{complaintId}</Link>
</div>

<div className=" bg-white py-5 rounded-xl my-5 max-sm:px-5 px-10">
<div className="text-center">
{complaintData?.status=="SOLVED"?<>
<p  className="mr-2 text-2xl font-bold text-customGreen">{formattedDate}</p>
</>:<>
<p  className="mr-2 text-2xl font-bold text-yellowCustom">{formattedDate}</p>
</>}
    </div>   
    {complaintData?.status=="SOLVED"?<>
    <hr  className={`${Style.hr} bg-green-500 mt-2 `}/>
</>:<>
<hr  className={`${Style.hr} bg-yellowCustom mt-2 `}/>
</>}
   
<p className='text-xl text-gray-400 p-2 max-sm:px-0 max-sm:text-lg leading-8	 ' style={{color:'#4D4C4CCC'}}>
{complaintData?.complaintDetails}
 </p>

 {complaintData?.status=="SOLVED"?<>
 <hr  className={`${Style.hr2} bg-green-500 mt-2 `}/>
</>:<>
<hr  className={`${Style.hr2} bg-yellowCustom mt-2 `}/>
</>}
 <div className="text-center flex felx-row justify-center pt-2 ">
 {complaintData?.status=="SOLVED"?<>
 <p  className="mr-2 text-xl font-bold text-customGreen pe-2 "> تم حل المشكلة بنجاح</p>
        <svg width="25" height="33" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34.8412 0L40 3.6309L17.6323 36H12.4735L0 18.4635L5.15881 13.6352L15.0529 22.9056L34.8412 0Z" fill="#32AA2F"/>
</svg>
</>:<>
<p  className="mr-2 text-xl font-bold text-yellowCustom pe-2 "> سيتم حل المشكلة في اسرع وقت <i className=' fa-solid fa-spinner fa-spin' style={{color:'#F4A922'}}></i>
</p>
</>}
    
     

    </div>      
    </div>
         


            


    </div>
    </>}
    


</>
}

export default ComplaintsDetails;
