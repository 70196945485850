import React, { useEffect, useState } from 'react';
import Style from './ChangePassword.module.css'
import logo from '../../images/logo512-removebg-preview.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios';
import { toast } from 'react-toastify';
const ChnagePassword = () => {
  const [password, setPassword] = useState('');
  const [repassword, setrePassword] = useState('');
  const [isSelectActive, setIsSelectActive] = useState(false);
  const handelSHowPassword =()=>{
    setPassword(!password)
  }
  const handelSHowPasswordres =()=>{
    setrePassword(!repassword)
  }
  const notify = (mess, type) => {
    toast[type](mess);
  };
  const [Loading, setLoading] = useState(false);
  let navigate =useNavigate()

 async function submitChange(values){
   setLoading(true);
  try {
    const response = await axios.post('https://itc-541ea27f6158.herokuapp.com/api/v1/auth/confirm-reset-password', values);
    
    if (response.data.statusCode) {
      notify("تم تغيير الباسورد بنجاح", "success");
      setLoading(false);
      navigate('/login');
    }
  } catch (error) {
    notify(error.response.data.message , "error");
    if (error.response.status === 405) {
      setLoading(false);
      navigate('/login') ;
       notify(error.response.data.message, "success");

    }
    setLoading(false);
  }
}
  
  let validationSchema=Yup.object({
    otp:Yup.string().min(6, 'otp Must Be 6 Digits').max(6 ,"otp Must Be 6 Digits").required("otp is required"),
    newPassword:Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,'Password Should Start with UpperCase and have a LowerCase and special character and min 8 and max 20').required('Password is required'),
    repassword:Yup.string().oneOf([Yup.ref("newPassword")],"Repassword Not Match Password").required("RePassword is required"),
  })
  let formik=useFormik({
    initialValues:{
      otp:'',
      email:useLocation().state.email,    
      newPassword:'',
      repassword:''
    },
    validationSchema
    ,
    onSubmit:submitChange
  })










    return <>
    <div className={` h-screen overflow-x-hidden ${Style.home} `}>
        <div className="grid grid-cols-2 ">
         <div className='col-span-1'></div>
        <div className='cols-span-1 max-sm:pt-20 max-sm:me-20 max-md:me-20  max-md:pt-8  '>
    <img src={logo} className='w-5/12 h-auto mx-auto max-sm:w-full max-md:w-9/12 max-md:mx-auto ' alt="" />

    <div className=" text-center flex justify-center align-middle items-center flex-col text-gray-700  ">
  <form className="max-w-screen-lg  mb-2  w-96" onSubmit={formik.handleSubmit}>
    <div className="flex flex-col gap-6 mb-1">
   
      <div className="relative w-full max-sm:w-9/12 max-sm:mx-auto  max-md:w-9/12 max-md:mx-auto  ">
     {formik.errors.otp && formik.touched.otp? <>
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.otp}</span>
</div>
     </>:null}
    
        <input onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.otp} name='otp' type='text' placeholder="ادخل الكود"
          className="placeholder-green-600 placeholder:opacity-75 text-base h-full w-full rounded-md border border-customGreen   p-3  text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  " />
          </div>


     


      

          {formik.errors.newPassword && formik.touched.newPassword? <>
      <div className="bg-red-100 border  border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.newPassword}</span>
</div>
     </>:null}
     
      <div className="relative w-full max-sm:w-9/12 max-sm:mx-auto  max-md:w-9/12 max-md:mx-auto  ">
      
     
        <input onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.newPassword} name='newPassword'  type={password ? "text" : "password"} placeholder="ادخل كلمة المرور"
          className="placeholder-green-600 placeholder:opacity-75 text-base h-full w-full rounded-md border border-customGreen   p-3  text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  " />
           { password ?   
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={22}
  height={20}
  className='eyaIcon'
  onClick={handelSHowPassword}
  viewBox="0 0 576 512">

  <path style={{fill: 'green'}} d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
  </svg>
:
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={22}
  height={20}
  className='eyaIcon'
  onClick={handelSHowPassword}  
            
  viewBox="0 0 640 512">


  <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" style={{fill: 'green'}}/></svg>

  }
          </div>

          {formik.errors.repassword && formik.touched.repassword? <>
      <div className="bg-red-100 border  border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.repassword}</span>
</div>
     </>:null}

      <div className="relative  w-full max-sm:w-9/12 max-sm:mx-auto max-md:w-9/12 max-md:mx-auto   ">
      
        <input onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.repassword} name='repassword'  type={repassword ? "text" : "password"} placeholder="تأكيد كلمة المرور"
          className="h-full w-full placeholder-green-500     rounded-md border border-customGreen   p-3  text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  " />
           { repassword ?   
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={22}
  height={20}
  className='eyaIcon'
  onClick={handelSHowPasswordres}
  viewBox="0 0 576 512">

  <path style={{fill: 'green'}} d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
  </svg>
:
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={22}
  height={20}
  className='eyaIcon'
  onClick={handelSHowPasswordres}  
            
  viewBox="0 0 640 512">


  <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" style={{fill: 'green'}}/></svg>

  }
          <label
          className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent "></label>
      </div>
    </div>
  
  {Loading?<>
    <button 
      className="mt-6 mx-auto block w-6/12   select-none rounded-lg bg-customGreen py-3 px-6 text-center align-middle font-sans text-lg font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg"
      type="button">
     <i className='fas fa-spinner fa-spin'></i>
         </button>
  </>:<>
  <button 
      className="mt-6 mx-auto block w-6/12   select-none rounded-lg bg-customGreen py-3 px-6 text-center align-middle font-sans text-lg font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg"
      type="submit">
     
تغيير الباسورد   </button>
  </>}
  <p className="block mt-4 font-sans text-base antialiased font-normal leading-relaxed text-center text-gray-700">
    تذكرت كلمه المرور ؟
      <Link to={'/login'} className=" ps-1 text-customGreen font-bold hover:underline">
      قم بتسجيل الدخول</Link>
    </p>
  
  
  </form>
</div>  

            </div>
        </div>
    </div>
    </>
       
}

export default ChnagePassword;
