import React, { useEffect, useState } from 'react';
import Style from './ResetPassword.module.css'
import logo from '../../images/logo512-removebg-preview.png'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios';
import { toast } from 'react-toastify';
const ResetPassword = () => {
  const notify = (mess, type) => {
    toast[type](mess);
  };
  const [Loading, setLoading] = useState(false);
  let navigate =useNavigate()
 async function submitReset(values){
  setLoading(true);
  
  try {
    const response = await axios.post('https://itc-541ea27f6158.herokuapp.com/api/v1/auth/reset-password', values);
    if (response.data.statusCode) {
      notify("تم ارسال الكود علي الايميل", "success");
      setLoading(false);
      navigate('/ChnagePassword', { state: { email: values.email } });
    }
  } catch (error) {
    notify(error.response.data.message, "error");

    setLoading(false);
  }
}
  
  let validationSchema=Yup.object({
    email:Yup.string().email("Email is invalid").required('Email is required'),
   

  })
  let formik=useFormik({
    initialValues:{
      email:'',
    },
    validationSchema
    ,
    onSubmit:submitReset
  })

    return <>
    <div className={` h-screen overflow-hidden ${Style.home} `}>
        <div className="grid grid-cols-2 ">
         <div className='col-span-1'></div>
        <div className='cols-span-1 max-sm:pt-20 max-sm:me-20 max-md:me-20  max-md:pt-8  '>
    <img src={logo} className='w-5/12 h-auto mx-auto max-sm:w-full max-md:w-9/12 max-md:mx-auto ' alt="" />

    <div className=" text-center flex justify-center align-middle items-center flex-col text-gray-700  ">
  <form className="max-w-screen-lg  mb-2  w-96" onSubmit={formik.handleSubmit}>
    <div className="flex flex-col gap-6 mb-1">
   
    <div className="relative w-full max-sm:w-9/12 max-sm:mx-auto  max-md:w-9/12 max-md:mx-auto  ">
      {formik.errors.email && formik.touched.email? <>
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.email}</span>
</div>
     </>:null}
        <input onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} name='email' type='email' placeholder="ادخل البريد الألكتروني"
          className="placeholder-green-600 placeholder:opacity-75 text-base h-full w-full rounded-md border border-customGreen   p-3  text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  " />
          </div>

    </div>
 
    {Loading?<>
    <button 
      className="mt-6 mx-auto block w-6/12   select-none rounded-lg bg-customGreen py-3 px-6 text-center align-middle font-sans text-lg font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg"
      type="button">
     <i className='fas fa-spinner fa-spin'></i>
         </button>
  </>:<>
  <button 
      className="mt-6 mx-auto block w-6/12   select-none rounded-lg bg-customGreen py-3 px-6 text-center align-middle font-sans text-lg font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg"
      type="submit">
ارسال الكود
  </button>
  </>}
    <p className="block mt-4 font-sans text-base antialiased font-normal leading-relaxed text-center text-gray-700">
    تذكرت كلمه المرور ؟
      <Link to={'/login'} className=" ps-1 text-customGreen font-bold hover:underline">
      قم بتسجيل الدخول</Link>
    </p>
  </form>
</div>  

            </div>
        </div>
    </div>
    </>
       
}

export default ResetPassword;
