import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Style from './SubScripe.module.css'
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const SubScripe = () => {
  const [loading, setloading] = useState(false);
  const [courses, setCourses] = useState([]);
  const notify = (mess, type) => {
    toast[type](mess);
  };
  let navigate =useNavigate()
useEffect(() => {
  const fetchData = async () => {
    try {
      setloading(true)
      const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/subscription/student-subscriptions`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      setloading(false)
      setCourses(response?.data.data);
    } catch (error) {
      if (error.response.status === 405) {
        setloading(false)
        navigate('/login') ;
         notify(error.response.data.message, "success");
  
      }
      setloading(false)

      console.error('Error fetching course data:', error);
      // Handle error
    }
  };

  fetchData();
}, []);

    return <>
    <Helmet>
    <title>أشتركاتي</title>
    </Helmet>
    {loading?<>
        <div className={`${Style.home2} w-full flex justify-center`}>
                <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

</div></>:<>
<div className={`${Style.home} w-full bg-gray-100 rounded-xl min-h-screen  `}>
    <div className="flex items-center max-sm:justify-center bg-white p-5 rounded-xl my-5">
<p to={'/My_subscriptions'} className="mr-2 text-maingreen font-bold">دوراتي التعليمية</p>
</div>
  



         
    <div className="grid  gap-4  xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  sm:grid-cols-2  ">

{courses.map((course)=>{
 return <div key={course.subscribedCourseId}>
  {course?.subscriptionStatus=="PENDING"?<>
  <div  className={`cursor-pointer block p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 `}>


<div className="flex items-center justify-between pt-3">
<h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 ">{course?.subscribedCourseTitle}</h5>
<i className=' fa-solid fa-spinner fa-spin' style={{color:'#F4A922'}}></i>



  </div>
  <h5 className="mb-2 text-lg tracking-tight text-gray-500 ">{course?.subscribedCourseTitle}</h5>

</div></>:<>
  <Link to={`/My_subscriptions/${course.subscribedCourseTitle}/${course.subscribedCourseId}/Subject`} className={`block p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 `}>


<div className="flex items-center justify-between pt-3">
<h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 ">{course?.subscribedCourseTitle}</h5>




  </div>
  <h5 className="mb-2 text-lg tracking-tight text-gray-500 ">{course?.subscribedCourseTitle}</h5>

</Link>
</>}

 </div>

})}





</div>

            


    </div>

</>}
    
  

</>
}

export default SubScripe;
