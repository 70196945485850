import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Style from './Complaints.module.css'
import axios from 'axios';
import { format } from 'date-fns';
import { ar } from 'date-fns/locale';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';


const Complaints = () => {
       const [loading, setloading] = useState(false);
       const [ComplaintsData, setComplaintsData] = useState([]);
       const notify = (mess, type) => {
        toast[type](mess);
      };
      let navigate =useNavigate()
       useEffect(() => {
         const fetchData = async () => {
           try {
             setloading(true)
             const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/complaints/student`, {
               headers: {
                 Authorization: `Bearer ${localStorage.getItem('userToken')}`
               }
             });
             setloading(false)
             setComplaintsData(response?.data.data);
           } catch (error) {
             setloading(false)
             if (error.response.status === 405) {
              setloading(false)
              navigate('/login') ;
               notify(error.response.data.message, "success");
        
            }
             console.error('Error fetching Complaints data:', error);
             // Handle error
           }
         };
     
         fetchData();
     
       }, []);
    return <>
    <Helmet>
          <title>الشكاوي</title>
          </Helmet>
          {loading?<>
              <div className={`${Style.home2} w-full flex justify-center`}>
              <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

</div>
          </>:<>
          <div className={`${Style.home} bg-gray-100 min-h-screen w-full     rounded-tr-3xl    `}>
    
<div className="flex items-center max-sm:justify-center bg-white p-5 rounded-xl my-5">
      <p to={'/'} className="mr-2 text-maingreen font-bold">قائمة الشكاوي</p>
    </div> 

            
            <div className={`  grid  gap-3 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  sm:grid-cols-2 `}>
            <Link to={'/My_Complaints/NewComplaints'} className={`  p-4 bg-customGreen text-white border border-gray-200 rounded-lg shadow hover:bg-green-700`}>
   
   
   <div className="flex flex-col items-center pt-4 justify-center  ">
   <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.9004 38.3752H32.6009V32.8543H38.1217V31.1539H32.6009V25.6331H30.9004V31.1539H25.3796V32.8543H30.9004V38.3752ZM31.7507 40.8375C29.2906 40.8375 27.203 39.9806 25.4878 38.267C23.7727 36.5533 22.9158 34.4657 22.9173 32.0041C22.9173 29.5455 23.7741 27.4586 25.4878 25.7435C27.2015 24.0284 29.2891 23.1708 31.7507 23.1708C34.2107 23.1708 36.2983 24.0284 38.0135 25.7435C39.7286 27.4586 40.5855 29.5455 40.584 32.0041C40.584 34.4657 39.7271 36.5533 38.0135 38.267C36.2969 39.9806 34.2093 40.8375 31.7507 40.8375ZM8.56315 11.2811H28.4382V9.07279H8.56315V11.2811ZM17.4429 36.1668H4.40044C3.41994 36.1668 2.58078 35.8172 1.88294 35.1179C1.18364 34.42 0.833984 33.5809 0.833984 32.6004V4.39995C0.833984 3.41945 1.18364 2.58029 1.88294 1.88245C2.58078 1.18315 3.41994 0.833496 4.40044 0.833496H32.6009C33.5814 0.833496 34.4205 1.18315 35.1184 1.88245C35.8177 2.58029 36.1673 3.41945 36.1673 4.39995V17.5395C35.44 17.3084 34.7106 17.1273 33.9789 16.9963C33.2472 16.8667 32.5044 16.802 31.7507 16.802C31.0057 16.802 30.2814 16.8608 29.5777 16.9786C28.8739 17.0964 28.182 17.2628 27.5018 17.4777V17.396H8.56315V19.6043H23.1094C21.9935 20.3714 21.013 21.2716 20.1679 22.3051C19.3229 23.3386 18.6273 24.4767 18.0811 25.7192H8.56315V27.9275H17.3082C17.1492 28.5503 17.0247 29.1782 16.9349 29.8112C16.8466 30.4443 16.8024 31.0906 16.8024 31.7502C16.8024 32.4274 16.8422 33.1701 16.9217 33.9784C16.9997 34.7866 17.1734 35.5161 17.4429 36.1668Z" fill="#FBFBFB"/>
</svg>
   <h5 className=" text-lg font-bold tracking-tight">اضافة شكوي جديدة</h5>

       </div>
   
       

</Link>
{ComplaintsData?.map((complaint)=>{
       return <>
       <div key={complaint.complaintId}>
       <Link to={`/My_Complaints/ComplaintsDetails/${complaint?.complaintId}`} className={`block  h-51 pb-8 p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 `}>
   
   
   <div className="flex items-center justify-between py-2">
   <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900"> رقم الشكوي : {complaint?.complaintId} </h5>
   {complaint.status=="SOLVED"?<>
   <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.1627 0L22 2.01717L9.69779 20H6.86044L0 10.2575L2.83734 7.57511L8.27911 12.7253L19.1627 0Z" fill="#32AA2F"/>
</svg>
   </>:<>
   <i className='fa-solid fa-spinner fa-spin' style={{color:'#F4A922'}}></i>
   </>}
  

       </div>
       {complaint.status=="SOLVED"?<>
       <h5 title={complaint?.complaintDetails} className="text-lg tracking-tight text-customGreen">
  {complaint?.complaintDetails && complaint.complaintDetails.length > 40
    ? `${complaint.complaintDetails.slice(0, 40)}...`
    : complaint?.complaintDetails}
</h5>
   </>:<>
   <h5 title={complaint?.complaintDetails} className=" text-lg tracking-tight text-yellowCustom"> 
   {complaint?.complaintDetails && complaint.complaintDetails.length > 50
    ? `${complaint.complaintDetails.slice(0, 50)}...`
    : complaint?.complaintDetails}</h5>   </>}
      
       <div className='float-left '>
      <p className='text-customGray text-sm'>{format(new Date(complaint?.date), 'd MMMM yyyy', { locale: ar })}</p>
      </div>


</Link>
       </div>
      
       </>
          
})}
       
            </div>
            </div>
          </>}


  

         
    </>
}

export default Complaints;
