import React, { useContext, useEffect, useState } from 'react';
import Style from './support.module.css'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import { UserContext } from '../../Context/userContext';
import { BallTriangle } from 'react-loader-spinner';

const Support = () => {
          
    
         function technical_support() {
             
                 return axios.get('https://itc-541ea27f6158.herokuapp.com/api/v1/technical-support/phone-number', {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('userToken')}`
                    }
                  });
                
              }
 
let {isFetching,isLoading,isError,data}=useQuery('featuredCourses',technical_support)

   return <>
          <Helmet>
          <title>الرئيسيه</title>
          </Helmet>
          {isLoading?<>
                <div className={`${Style.home2} w-full flex justify-center`}>
                <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

</div>
          </>:<>
          <div className={`${Style.home} bg-gray-100 rounded-xl w-full min-h-screen  `}>
          <div className="flex items-center max-sm:justify-center bg-white p-5 rounded-xl my-5">
      <p to={'/'} className="mr-2 text-maingreen font-bold">التواصل </p>
    </div>  
               
          <div className="grid  gap-4 grid-flow-row  ">
            <Link to={`whatsapp://send?phone=${data?.data.data.whatsapp}`}>

          <div class="bg-white hover:bg-gray-300   rounded-lg p-2 px-8 grid md:grid-cols-3 sm:grid-cols-1 ">
    <div class="col-span-1 flex items-center">
                <svg width="40" height="40" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="22.5" cy="22.5" r="22.5" fill="#ECECEC"/>
<g filter="url(#filter0_d_441_11)">
<path d="M8.61863 22.3743C8.61795 24.819 9.25958 27.206 10.4796 29.3099L8.50195 36.4985L15.8915 34.5696C17.9354 35.6773 20.2254 36.2577 22.5525 36.2578H22.5586C30.2407 36.2578 36.4942 30.0345 36.4975 22.3853C36.499 18.6787 35.0503 15.1932 32.4183 12.5709C29.7868 9.94891 26.287 8.50413 22.558 8.50244C14.8749 8.50244 8.62192 14.7254 8.61875 22.3743" fill="url(#paint0_linear_441_11)"/>
<path d="M8.12121 22.3695C8.12042 24.9021 8.78504 27.3745 10.0486 29.5537L8 37L15.6545 35.0019C17.7636 36.1467 20.1382 36.7503 22.5545 36.7512H22.5607C30.5185 36.7512 36.9966 30.304 37 22.381C37.0014 18.5412 35.5006 14.9304 32.7746 12.2142C30.0483 9.49834 26.4233 8.00158 22.5607 8C14.6016 8 8.12438 14.4463 8.12121 22.3695ZM12.6796 29.1784L12.3938 28.7268C11.1924 26.8249 10.5582 24.6271 10.5591 22.3704C10.5617 15.7848 15.9453 10.4269 22.5653 10.4269C25.7711 10.4283 28.7839 11.6724 31.05 13.9298C33.316 16.1873 34.5629 19.1883 34.5621 22.3801C34.5591 28.9656 29.1754 34.3242 22.5607 34.3242H22.556C20.4021 34.323 18.2898 33.7472 16.4476 32.659L16.0092 32.4002L11.4669 33.5858L12.6796 29.1783V29.1784Z" fill="url(#paint1_linear_441_11)"/>
<path d="M18.9527 16.3624C18.6824 15.7643 18.398 15.7522 18.1409 15.7418C17.9304 15.7327 17.6898 15.7334 17.4495 15.7334C17.2088 15.7334 16.8179 15.8235 16.4875 16.1827C16.1567 16.5422 15.2246 17.4111 15.2246 19.1782C15.2246 20.9454 16.5175 22.6531 16.6977 22.893C16.8782 23.1324 19.1936 26.8748 22.8608 28.3145C25.9085 29.5109 26.5287 29.273 27.1902 29.213C27.8517 29.1532 29.3248 28.3444 29.6254 27.5056C29.9261 26.667 29.9261 25.9482 29.836 25.798C29.7458 25.6483 29.5052 25.5584 29.1444 25.3789C28.7835 25.1993 27.0097 24.3303 26.679 24.2104C26.3483 24.0907 26.1078 24.0309 25.8672 24.3905C25.6265 24.7496 24.9356 25.5585 24.7251 25.798C24.5147 26.0381 24.3041 26.068 23.9434 25.8883C23.5824 25.7081 22.4205 25.3293 21.0419 24.1058C19.9694 23.1537 19.2453 21.978 19.0348 21.6184C18.8243 21.2593 19.0123 21.0647 19.1932 20.8857C19.3553 20.7248 19.5541 20.4663 19.7347 20.2566C19.9146 20.0469 19.9746 19.8972 20.0949 19.6577C20.2153 19.4179 20.1551 19.2081 20.065 19.0285C19.9746 18.8488 19.2735 17.0725 18.9527 16.3624Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_441_11" x="5" y="8" width="35" height="35" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="1.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_441_11"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_441_11" result="shape"/>
</filter>
<linearGradient id="paint0_linear_441_11" x1="1408.28" y1="2808.11" x2="1408.28" y2="8.50244" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FAF38"/>
<stop offset="1" stop-color="#60D669"/>
</linearGradient>
<linearGradient id="paint1_linear_441_11" x1="1458" y1="2908" x2="1458" y2="8" gradientUnits="userSpaceOnUse">
<stop stop-color="#F9F9F9"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
</defs>
</svg>
<h2 className='ps-2'>واتساب</h2>
                </div>
    <div class="col-span-2 flex items-center">
          {data?.data.data.whatsapp?<>
            <h2 className='p-2 tracking-wider overflow-hidden sm:overflow-visible'>
              {data?.data.data.whatsapp}</h2>

          </>:<>
          <h2 className='ps-2 '>لا يوجد رقم واتساب الان</h2>
</>}
                </div>
             
          

            </div>
            </Link>
            <Link to={data?.data.data.telegram}>

          <div class="bg-white hover:bg-gray-300 rounded-lg p-2 px-8 grid md:grid-cols-3 sm:grid-cols-1">
    <div class="col-span-1 flex items-center">
    <svg width="40" height="40" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="22.5" cy="22.5" r="22.5" fill="#ECECEC"/>
<g clip-path="url(#clip0_441_23)">
<path d="M22.5 8C18.6552 8 14.9645 9.52862 12.248 12.2469C9.52876 14.9663 8.00075 18.6543 8 22.5C8 26.3441 9.5293 30.0348 12.248 32.7531C14.9645 35.4714 18.6552 37 22.5 37C26.3448 37 30.0355 35.4714 32.752 32.7531C35.4707 30.0348 37 26.3441 37 22.5C37 18.6559 35.4707 14.9652 32.752 12.2469C30.0355 9.52862 26.3448 8 22.5 8Z" fill="url(#paint0_linear_441_23)"/>
<path d="M14.5628 22.347C18.7904 20.5055 21.6089 19.2914 23.0181 18.7048C27.0464 17.0299 27.8824 16.7389 28.4284 16.7291C28.5485 16.7272 28.8158 16.7568 28.9903 16.8979C29.1353 17.0168 29.1761 17.1777 29.1965 17.2906C29.2146 17.4035 29.2395 17.6606 29.2191 17.8613C29.0016 20.1541 28.0568 25.7181 27.5765 28.2862C27.3749 29.3728 26.9739 29.7371 26.5865 29.7726C25.7436 29.8501 25.1047 29.2162 24.2891 28.6817C23.0136 27.845 22.2931 27.3244 21.0538 26.5081C19.6219 25.5647 20.5508 25.0461 21.3665 24.1987C21.5794 23.9769 25.2905 20.6023 25.3608 20.2962C25.3698 20.2579 25.3789 20.1152 25.2928 20.04C25.209 19.9645 25.0843 19.9903 24.9937 20.0107C24.8646 20.0397 22.8278 21.3873 18.8765 24.0533C18.2988 24.4507 17.7754 24.6444 17.3042 24.6342C16.7876 24.6231 15.7908 24.3415 15.0499 24.1009C14.1436 23.8057 13.4209 23.6496 13.4843 23.1482C13.5161 22.8872 13.8763 22.6201 14.5628 22.347Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_441_23" x1="1458" y1="8" x2="1458" y2="2908" gradientUnits="userSpaceOnUse">
<stop stop-color="#2AABEE"/>
<stop offset="1" stop-color="#229ED9"/>
</linearGradient>
<clipPath id="clip0_441_23">
<rect width="29" height="29" fill="white" transform="translate(8 8)"/>
</clipPath>
</defs>
</svg>

<h2 className='ps-2'>تيليجرام</h2>
                </div>
    <div class="col-span-2 flex items-center">
          {data?.data.data.telegram?<>
            <h2 className='p-2 tracking-wider overflow-hidden sm:overflow-visible'>
              {data?.data.data.telegram}</h2>

          </>:<>
          <h2 className='ps-2 '>لا يوجد رقم تيليجرام الان</h2>
</>}
                </div>
             
          

            </div>
            </Link>

            <Link to={data?.data.data.facebook}>
          <div class="bg-white hover:bg-gray-300  rounded-lg p-2 px-8 grid md:grid-cols-3 sm:grid-cols-1">
    <div class="col-span-1 flex items-center">
    <svg width="40" height="40" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="22.5" cy="22.5" r="22.5" fill="#ECECEC"/>
<g clip-path="url(#clip0_441_33)">
<path d="M37 22.5C37 14.4919 30.5081 8 22.5 8C14.4919 8 8 14.4919 8 22.5C8 29.7373 13.3025 35.7361 20.2344 36.8238V26.6914H16.5527V22.5H20.2344V19.3055C20.2344 15.6714 22.3992 13.6641 25.7113 13.6641C27.2977 13.6641 28.957 13.9473 28.957 13.9473V17.5156H27.1287C25.3274 17.5156 24.7656 18.6334 24.7656 19.7801V22.5H28.7871L28.1442 26.6914H24.7656V36.8238C31.6975 35.7361 37 29.7374 37 22.5Z" fill="#1877F2"/>
<path d="M28.1442 26.6914L28.7871 22.5H24.7656V19.7801C24.7656 18.6333 25.3274 17.5156 27.1287 17.5156H28.957V13.9473C28.957 13.9473 27.2977 13.6641 25.7112 13.6641C22.3992 13.6641 20.2344 15.6714 20.2344 19.3055V22.5H16.5527V26.6914H20.2344V36.8238C20.9839 36.9413 21.7414 37.0002 22.5 37C23.2586 37.0002 24.0161 36.9413 24.7656 36.8238V26.6914H28.1442Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_441_33">
<rect width="29" height="29" fill="white" transform="translate(8 8)"/>
</clipPath>
</defs>
</svg>

<h2 className='ps-2'>فيسبوك</h2>
                </div>
    <div class="col-span-2 flex items-center">
          {data?.data.data.facebook?<>
            <h2 className='p-2 tracking-wider overflow-hidden sm:overflow-visible'>
              {data?.data.data.facebook}</h2>

          </>:<>
          <h2 className='ps-2 '>لا يوجد رابط فيسبوك الان</h2>
</>}
                </div>
             
          

            </div>
            </Link>
            <Link to={`mailto:${data?.data.data.email}`}>
  <div class="bg-white hover:bg-gray-200 rounded-lg p-2  px-8 grid md:grid-cols-3 sm:grid-cols-1">
    <div class="col-span-1 flex items-center">
      <svg width="40" height="40" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
       
<circle cx="22.5" cy="22.5" r="22.5" fill="#ECECEC"/>
<path d="M34.3477 20.1333H33.3743V20.0832H22.4993V24.9165H29.3282C28.332 27.7301 25.6549 29.7498 22.4993 29.7498C18.4955 29.7498 15.2493 26.5036 15.2493 22.4998C15.2493 18.496 18.4955 15.2498 22.4993 15.2498C24.3475 15.2498 26.0289 15.947 27.3091 17.0859L30.7269 13.6681C28.5688 11.6569 25.6821 10.4165 22.4993 10.4165C15.8263 10.4165 10.416 15.8268 10.416 22.4998C10.416 29.1729 15.8263 34.5832 22.4993 34.5832C29.1724 34.5832 34.5827 29.1729 34.5827 22.4998C34.5827 21.6896 34.4993 20.8988 34.3477 20.1333Z" fill="#FFC107"/>
<path d="M11.8105 16.8756L15.7805 19.7871C16.8547 17.1276 19.4563 15.2498 22.5007 15.2498C24.3488 15.2498 26.0302 15.947 27.3104 17.0859L30.7282 13.6681C28.5701 11.6569 25.6834 10.4165 22.5007 10.4165C17.8595 10.4165 13.8345 13.0368 11.8105 16.8756Z" fill="#FF3D00"/>
<path d="M22.4994 34.5833C25.6205 34.5833 28.4565 33.3888 30.6006 31.4464L26.8609 28.2818C25.6069 29.2354 24.0747 29.7512 22.4994 29.7499C19.3565 29.7499 16.6879 27.7459 15.6826 24.9492L11.7422 27.9852C13.742 31.8983 17.8032 34.5833 22.4994 34.5833Z" fill="#4CAF50"/>
<path d="M34.3483 20.1336H33.375V20.0835H22.5V24.9168H29.3289C28.8523 26.2559 27.9939 27.426 26.8597 28.2826L26.8615 28.2814L30.6013 31.4461C30.3366 31.6865 34.5833 28.5418 34.5833 22.5002C34.5833 21.69 34.5 20.8991 34.3483 20.1336Z" fill="#1976D2"/>
</svg>     
      <h2 className='ps-2'>جيميل</h2>
    </div>
    {data?.data.data.email ? (
      <h2 className='p-2 tracking-wider overflow-hidden sm:overflow-visible'>
        {data?.data.data.email}
      </h2>
    ) : (
      <h2 className='ps-2'>لا يوجد ايميل جيميل الان</h2>
    )}
  </div>
</Link>

</div>

                  


          </div>
          </>}

       

    </>
       
}

export default Support;
