import React, { useEffect, useState } from 'react';
import Style from './Subject_SubScription.module.css'
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const SubjectSubScription = () => {
  const [loading, setloading] = useState(false);
  const notify = (mess, type) => {
    toast[type](mess);
  };
    let {courseId,name}=useParams()
    function getSubjets() {
             
        return axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/course-subject/course/${courseId}`, {
           headers: {
             Authorization: `Bearer ${localStorage.getItem('userToken')}`
           }
         });
       
     }
     let {isFetching,isLoading,isError,data}=useQuery('featuredSubject',getSubjets,{
      cacheTime:100
     })

  
      const [courseData, setCourseData] = useState(null);
const navigate=useNavigate()
      useEffect(() => {
        const fetchData = async () => {
          try {
            setloading(true)
            const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/course/${courseId}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
              }
            });
            setloading(false)
            setCourseData(response?.data.data);
          } catch (error) {
            setloading(false)
            if (error.response.status === 405) {
              setloading(false)
              navigate('/login') ;
               notify(error.response.data.message, "success");
        
            }

            console.error('Error fetching course data:', error);
            // Handle error
          }
        };
    
        fetchData();
    
      }, []);

    return <>
    <Helmet>
          <title>الموضوعات</title>
          </Helmet>


    {isLoading && loading?<>
        <div className={`${Style.home2} w-full flex justify-center`}>
                <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

</div></>:<>
<div className={`${Style.home} bg-gray-100 min-h-screen     rounded-tr-3xl    `}>
    
<div className="  flex items-center bg-white p-5 rounded-xl my-5">
<div className="flex items-center">
        <Link to={'/'} className="mr-2 text-green-500">{name}</Link>
    </div>   
     <div className="flex items-end pt-1">
        <span className='px-2 max-sm:px-0.5'>
            <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
            </svg>
        </span>
    </div>
    <Link to={`/${name}/${courseId}/subjects`} className='font-bold text-green-600'>الموضوعات</Link>
</div>
<div className=" bg-white py-5 rounded-xl my-5 max-sm:px-5 px-10">
<div className="text-center">
        <p  className="mr-2 text-2xl font-bold text-customGreen">عن الدورةالتعليمية</p>
    </div>   
    <hr  className={`${Style.hr} bg-green-500 mt-2 `}/>
   
<p className='text-xl text-gray-400 p-2 max-sm:px-0 max-sm:text-lg ' style={{letterSpacing: '1.5px',color:'#4D4C4CCC'}}>
{courseData?.courseDescription}
</p>
    </div>

            
            <div className={`  grid  gap-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  sm:grid-cols-2 `}>
            {data?.data.data.map((subject)=>{
                return <>
                <div key={subject.subjectId}>
                <Link to={`/My_subscriptions/${name}/${courseId}/${subject.subjectTitle}/${subject.subjectId}/Videos`}  className={` block  p-3 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 `}>
     
     
     <div className="flex items-center justify-between pt-2">
     <h5 className="mb-1 text-lg font-medium tracking-tight text-gray-900 ">{subject?.subjectTitle}</h5>

     <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.1627 0L22 2.01717L9.69779 20H6.86044L0 10.2575L2.83734 7.57511L8.27911 12.7253L19.1627 0Z" fill="#32AA2F"/>
</svg>

         </div>
         <h5 className="mb-2 text-lg tracking-tight text-green-500 ">{subject?.subjectDescription}</h5>
            <p className='text-progress_text'> عدد الفيديوهات : {subject.numberOfVideos} </p>
 </Link>
                </div>
                </>
            })}




 
 



   
  

  </div>

  
  
                    
  
  
            </div>
</>}
  

         
    </>
       
}

export default SubjectSubScription;
