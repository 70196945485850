import React, { useEffect, useState } from 'react';
import SecondNavbar from '../SecondNavbar/SecondNavbar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Style from './FinalExam.module.css';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const FinalExam = () => {
    const notify = (mess, type) => {
        toast[type](mess);
      };
      let navigate =useNavigate()

    const [examDetails, setExamDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [Error, setError] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    let { videoId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/quizzes?videoId=${videoId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('userToken')}`
                    }
                });
                setLoading(false);
                setExamDetails(response?.data?.data);
            } catch (error) {
                if (error.response.status === 405) {
                    setLoading(false)
                    navigate('/login') ;
                     notify(error.response.data.message, "success");
              
                  }
                setLoading(false);
                console.error('Error fetching course data:', error);

                // Handle error
            }
        };

        fetchData();

    }, [videoId]);

    const handleAnswerChange = (questionId, answer) => {
        setSelectedAnswers(prevState => ({
            ...prevState,
            [questionId]: answer
        }));
    };

    const handleSubmitExam = async () => {
        try {
            const solutions = Object.entries(selectedAnswers).map(([questionId, selectedAnswer]) => ({
                questionId,
                correctAnswer: selectedAnswer
            }));
            const response = await axios.post(
                `https://itc-541ea27f6158.herokuapp.com/api/v1/quizzes/${examDetails?.quizId}/solve`,
                { solutions },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('userToken')}`
                    }
                }
            );
          
            notify("تم أرسال الاختبار بنجاح", "success");
            navigate('/My_exams')   

        } catch (error) {
            if (error.response.status==400) {
                notify(error.response.data.message, "error");
                navigate('/My_exams')   
            }
            navigate('/')        
                        console.error('Error submitting exam:', error);
            // Handle error
        }
    };
    return <>

            {!Error?<>
                <SecondNavbar />
                {loading?<>
    <div className={`${Style.home2} w-full flex justify-center`}>
            <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />
          </div>
          </>:
          <>
          <div className={`${Style.home} bg-gray-100 min-h-screen rounded-tr-3xl`}>
                <div className="bg-white p-5 rounded-xl my-5 flex justify-between">
                    <div></div>
                    <p to={'/Subject'} className='font-bold text-progress_text mb-2'>{examDetails?.quizTitle}</p>
                    <p to={'/Subject'} className='text-customGreen '>{examDetails?.questions.length} سؤال</p>
                </div>
                {examDetails?.questions.map((question) => (
                    <div key={question?.id} className='bg-white p-10 rounded-2xl border-green-600 mt-3 border-2'>
                        <div className='flex flex-row items-center'>
                            <span className='bg-customGreen rounded-full p-1 m-2'></span>
                            <h1 className='text-lg text-black'>{question?.question}</h1>
                        </div>
                        <div className='grid grid-cols-3 max-md:grid-cols-1 pt-5 justify-center items-center '>
                            <div className='grid grid-cols-2  gap-5 max-md:w-11/12 col-span-2'>
                                {Object.entries(question).map(([key, value]) => {
                                    if (key.startsWith('answer')) {
                                        return (
                                            <div key={key}>
                                                <input
                                                    type="radio"
                                                    id={`${question.id}-${key}`}
                                                    name={question.id}
                                                    value={value}
                                                    className="hidden peer"
                                                    onChange={() => handleAnswerChange(question?.id, value)}
                                                    required
                                                />
                                                <label
                                                    htmlFor={`${question.id}-${key}`}
                                                    className="flex items-center justify-between max-md:max-w-max max-w-max p-5 text-gray-500 bg-gray-100 border border-gray-200 rounded-xl cursor-pointer peer-checked:bg-customGreen peer-checked:text-white hover:text-gray-600 hover:bg-gray-100"
                                                >
                                                    <div className="block">
                                                        <div className="text-lg font-semibold">{value}</div>
                                                    </div>
                                                </label>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                            <div className='w-full    max-lg:order-first max-lg:pb-2 '>
                            <a href={question?.imageUrl} target="_blank" rel="noopener noreferrer">
    <img src={question?.imageUrl} className='w-8/12 mx-auto h-auto rounded-lg' alt="" />
  </a>                            </div>
                        </div>
                    </div>
                ))}
                <div className='max-sm:w-12/12 text-center p-5 mt-5 w-6/12 m-auto max-sm:w-10/12 rounded-xl'>
                    <button className='bg-customGreen btn-ghost max-sm:px-12 max-sm:py-2 max-sm:text-base hover:bg-green-800 text-white p-5 px-24 text-3xl rounded-xl' onClick={()=>document.getElementById('my_modal_3').showModal()}>
                        ارسال الاختبار
                    </button>
                </div>
                <dialog id="my_modal_3" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg mb-4 max-md:text-sm">هل انت متأكد من ارسال الاختبار</h3>
  <div className="modal-action">
      <form method="dialog" className='mx-auto max-md:flex w-full'>
        {/* if there is a button in form, it will close the modal */}
              <button   onClick={handleSubmitExam} className="py-2.5 px-5   text-sm font-medium text-white  bg-customGreen rounded-lg border border-gray-200 hover:bg-green-700">
                   تأكيد الطلب
                    </button>
                <button className="text-white ms-3 bg-red-600 hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                    الغاء الطلب
                </button>
               
              </form>
    </div>
    </div>

</dialog>
            </div>
          </>}
            

            </>:<>
            <div className={`${Style.home} bg-gray-100 min-h-screen rounded-tr-3xl`}>
                <h1>{Error}</h1>
            </div>

            </>}

          
    


          
        </>
    
};

export default FinalExam;
