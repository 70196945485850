import React, { useContext, useEffect, useState } from 'react';
import Style from './Home.module.css'
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import { UserContext } from '../../Context/userContext';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const Home = () => {
  const notify = (mess, type) => {
    toast[type](mess);
  };
  let navigate =useNavigate()

  const [isLoading, setloading] = useState(false);
  const [CourseData, setCourseData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true)
        const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/course`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
        });
        setloading(false)
        setCourseData(response?.data?.data);
      } catch (error) {
        if (error.response.status === 405) {
          setloading(false)
          navigate('/login') ;
           notify(error.response.data.message, "success");
    
        }
        setloading(false)

        console.error('Error fetching course data:', error);
        // Handle error
      }
    };


    fetchData();

  }, []);
    
        
 
   return <>
          <Helmet>
          <title>الرئيسيه</title>
          </Helmet>
          {isLoading?<>
                <div className={`${Style.home2} w-full flex justify-center`}>
                <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

</div>
          </>:<>
          <div className={`${Style.home} bg-gray-100 rounded-xl w-full min-h-screen  `}>
          <div className="flex items-center max-sm:justify-center bg-white p-5 rounded-xl my-5">
      <p to={'/'} className="mr-2 text-maingreen font-bold">قائمة الدورات التعليمية</p>
    </div>  
               
          <div className="grid  gap-4  xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  sm:grid-cols-2  ">
{CourseData?.map((course)=>{
        return <>
        <div key={course?.courseId}>
        <Link to={{
                pathname: `/${course?.courseTitle}/${course?.courseId}/subjects`,
                state: { userStatus: course?.isFree } // Pass userStatus as state
            }}
 className={`block min-h-40 max-h-64  p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 `}>
   <div className="flex items-center justify-between pt-3">
   <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 ">{course?.courseTitle}</h5>
   {course?.isFree?<>
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_265_561)">
<path d="M29.0625 24.375C29.0625 26.9531 26.9531 29.0625 24.375 29.0625H5.625C3.04688 29.0625 0.9375 26.9531 0.9375 24.375V5.625C0.9375 3.04688 3.04688 0.9375 5.625 0.9375H24.375C26.9531 0.9375 29.0625 3.04688 29.0625 5.625V24.375Z" fill="white"/>
<path d="M15.9375 19.2188H19.6875V17.8125H17.2969V15.7031H19.6875V14.2969H17.2969V12.1875H19.6875V10.7812H15.9375V19.2188ZM25.3125 12.1875V10.7812H21.5625V19.2188H25.3125V17.8125H22.9219V15.7031H25.3125V14.2969H22.9219V12.1875H25.3125ZM11.2031 19.2188V15.7031H11.7188L12.8906 19.2188H14.2969L13.0781 15.5156C13.9219 15.1406 14.5312 14.25 14.5312 13.2188C14.5312 11.8594 13.5 10.7812 12.1875 10.7812H9.84375V19.2188H11.2031ZM11.2031 12.1875H12.1875C12.75 12.1875 13.1719 12.6563 13.1719 13.2188C13.1719 13.7813 12.7031 14.25 12.1875 14.25H11.2031V12.1875ZM6.04687 19.2188V15.7031H8.4375V14.2969H6.04687V12.1875H8.4375V10.7812H4.6875V19.2188H6.04687Z" fill="#009539"/>
</g>
<defs>
<clipPath id="clip0_265_561">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>
   </>:<>

   {course?.isSubscribed ?<>

    <i class="fa-solid fa-unlock fa-xl text-customGreen" ></i>
       </>:<>
       <i class="fa-solid fa-lock fa-xl text-customGreen" ></i>
   </>}

   </>}
 

       </div>
       <h5 className="mb-2 text-lg tracking-tight text-gray-500">{course?.courseDescription.slice(0, 150)}{course?.courseDescription.length > 100 ? '...' : ''}</h5>

<h6  className='text-sm text-left text-gray-900 opacity-70 font-semibold'>{course?.coursePrice}</h6>
</Link>
        </div>
       
        </>
})}
</div>

                  


          </div>
          </>}

       

    </>
       
}

export default Home;
