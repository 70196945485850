import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Style from './Exams.module.css'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const Exams = () => {
  const [loading, setloading] = useState(false);
  const [Exames, setExames] = useState([]);
  const notify = (mess, type) => {
    toast[type](mess);
  };
  let navigate =useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true)
        const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/quizzes/solved`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
        });
        setloading(false)
        setExames(response?.data.data);
      } catch (error) {
        if (error.response.status === 405) {
          setloading(false)
          navigate('/login') ;
           notify(error.response.data.message, "success");
    
        }
        setloading(false)

        console.error('Error fetching Complaints data:', error);
        // Handle error
      }
    };

    fetchData();

  }, []);
    return <>
    <Helmet>
    <title>نتائج الأختبارات السابقة</title>
    </Helmet>

    {loading?<>
      <div className={`${Style.home2} w-full flex justify-center`}>
            <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />
          </div>
    </>:<>
    <div className={`${Style.home} w-full bg-gray-100 rounded-xl min-h-screen  `}>
    <div className="flex items-center max-sm:justify-center bg-white p-5 rounded-xl my-5">
<p to={'/'} className="mr-2 text-maingreen font-bold">نتائج الأختبارات السابقة</p>
</div>  
         
    <div className="grid  gap-4  xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  sm:grid-cols-2  ">

{Exames.map((exam)=>{
  return<>
    <Link to={`/ExmasDetails/${exam?.quizId}`} key={exam.quizId} className={`cursor-pointer block  p-4 bg-white border border-gray-200 rounded-lg shadow  y-700 ray-700`}>


<div className="flex items-center justify-between pt-3">
<h5 className="mb-2 text-lg font-bold  text-progress_text ">{exam?.quizTitle}</h5>
  </div>
  <p className='text-gray-400 mb-5'>{exam?.quizDescription}</p>
  <div className='text-center'>
<p >{exam?.totalScore}/<span className={`
    ${exam?.solutionPercentage <= 50 ? 'text-error' : ''} 
    ${exam?.solutionPercentage > 51 && exam?.solutionPercentage <= 75 ? 'text-customGreen' : ''} 
    ${exam?.solutionPercentage > 76 && exam?.solutionPercentage == 100 ? 'text-info' : ''} 
    font-bold pe-1 text-xl
`}>{exam?.actualScore}</span></p>
  </div>
  <div className='text-center'>
<progress 
 className={`
 ${exam?.solutionPercentage <= 50 ? 'progress-error' : ''} 
 ${exam?.solutionPercentage > 51 && exam?.solutionPercentage <= 75 ? 'progress-success' : ''} 
 ${exam?.solutionPercentage > 76 && exam?.solutionPercentage == 100 ? 'progress-info' : ''} 
 w-11/12
 progress
`}
 value={exam?.solutionPercentage} max="100"></progress>
  </div>

</Link>
  </>

})}









</div>

            


    </div>
    </>}
    
    

</>
}

export default Exams;
