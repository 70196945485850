import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Style from './NewComplaints.module.css'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import axios from 'axios';

const NewComplaints = () => {
    let navigate=useNavigate()
    const notify = (mess, type) => {
        toast[type](mess);
      };
    const [Loading, setLoading] = useState(false);

 async   function submitComplaints(values) {
        setLoading(true); // Assuming setLoading is a state setter function to set loading state to true
    
  let response = await axios.post(
            `https://itc-541ea27f6158.herokuapp.com/api/v1/complaints`,
            values,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`
                }
            }
        )
            .then(response => {
                notify("تم ارسال الشكوي بنجاح", "success");
                navigate('/My_Complaints');
                setLoading(false); // Assuming setLoading is a state setter function to set loading state to false
            })
            .catch(error => {
                notify(error.response.data.data, "error");
                console.error('Error submitting complaint:', error);
                if (error.response.status === 405) {
                    setLoading(false)
                    navigate('/login') ;
                     notify(error.response.data.message, "success");
              
                  }
                setLoading(false); // Assuming setLoading is a state setter function to set loading state to false
            });
    }
    let validationSchema=Yup.object({
        complaintDetails:Yup.string().min(10, "Min length is 10").max(400,"max length is 400 ").required("complaintDetails Is required")
      })
      let formik=useFormik({
        initialValues:{
            complaintDetails:'',
        },
        validationSchema
        ,
        onSubmit:submitComplaints
      })
    return <>
    <Helmet>
    <title>الشكوي</title>
    </Helmet>
    
    <div className={`${Style.home} bg-gray-100 rounded-xl min-h-screen  `}>
    <div className="  flex items-center bg-white p-5 rounded-xl my-5">
<div className="flex items-center">
        <Link to={'/My_Complaints'} className="mr-2 text-green-500">قائمة الشكاوي</Link>
    </div>   
     <div className="flex items-end pt-1">
        <span className='px-2 mx-sm:px-0.5'>
            <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
            </svg>
        </span>
    </div>
    <p  className='font-bold text-green-600'>اضافة شكوي جديدة</p>
</div>
<form onSubmit={formik.handleSubmit}>


{formik.errors.complaintDetails && formik.touched.complaintDetails? <>
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.complaintDetails}</span>
</div>
     </>:null}
<textarea name='complaintDetails' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.complaintDetails} className="textarea resize-none textarea-success font-bold focus:outline-none w-full min-h-64 " placeholder="أخبرنا ما هي مشكلتك">
    
</textarea>


     
    <div className=' max-sm:w-12/12 text-center p-5 mt-5 w-6/12 m-auto max-sm:w-9/12 rounded-xl '>
    {Loading?<>
    <button 
      className="mt-6 mx-auto block w-6/12   select-none rounded-lg bg-customGreen py-3 px-6 text-center align-middle font-sans text-lg font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg"
      type="button">
     <i className='fas fa-spinner fa-spin'></i>
         </button>
  </>:<>
                        <button type="submit" className='bg-customGreen max-sm:w-12/12 btn-ghost max-sm:px-16 max-sm:py-4 max-sm:text-base   hover:bg-green-800 text-white p-5 px-24 text-3xl rounded-xl'>ارسال المشكلة</button>              
    </>}
    </div>
         
    </form>


            


    </div>

</>
}

export default NewComplaints;
