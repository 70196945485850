import React, { useContext, useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from './Components/Layout/Layout';
import './App.css';
import Home from './Components/Home/Home';
import NotFound from './Components/NotFound/NotFound';
import Subject from './Components/Subject/Subject';
import VideosSection from './Components/VideosSection/VideosSection';
import SubScripe from './Components/My_subscriptions/My_subscriptions';
import SubjectSubScription from './Components/Subject_SubScription/Subject_SubScription';
import VideosSubScription from './Components/Videos_SubScription/Videos_SubScription';
import Exams from './Components/Exams/Exams';
import VideoDetails from './Components/VideoDetails/VideoDetails';
import Complaints from './Components/Complaints/Complaints';
import ComplaintsDetails from './Components/ComplaintsDetails/ComplaintsDetails';
import NewComplaints from './Components/NewComplaints/NewComplaints';
import Login from './Components/Login/Login';
import Signup from './Components/Signup/Signup';
import FinalExam from './Components/FinalExam/FinalExam';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import ChnagePassword from './Components/ChangePassword/ChangePassword';
import UserContextProvider, { UserContext } from './Context/userContext';
import ProtectRoute from './Components/ProtectRoute/ProtectRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChangeOldPassword from './Components/changeOldPassword/changeOldPassword';
import { Profile } from './Components/Profile/Profile';
import Support from './Components/support/support';
import ExamDetails from './Components/ExamDetails/ExamDetails';



let router = createBrowserRouter([
  { path: '/login', element: <Login /> },
  { path: '/signup', element: <Signup /> },
  { path: '/reset_Password', element: <ResetPassword /> },
  { path: '/ChnagePassword', element: <ChnagePassword /> },
  { path: '*', element: <ProtectRoute><NotFound /></ProtectRoute>  },

  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element:<ProtectRoute><Home /></ProtectRoute>  },
      { path: '/:name/:courseId/subjects', element:<ProtectRoute><Subject /></ProtectRoute>  },
      { path: '/:name/:courseId/:subjectname/:subjectId/Videos', element:<ProtectRoute><VideosSection /></ProtectRoute> },
      { path: '/My_exams', element: <ProtectRoute><Exams /></ProtectRoute>  },
      { path: '/My_Complaints', element: <ProtectRoute><Complaints /> </ProtectRoute> },
      { path: '/Profile', element: <ProtectRoute><Profile/> </ProtectRoute> },
      { path: '/ChangeOldPassword', element: <ProtectRoute><ChangeOldPassword /> </ProtectRoute> },
      { path: '/My_Complaints/ComplaintsDetails/:complaintId', element: <ProtectRoute><ComplaintsDetails /></ProtectRoute>  },
      { path: '/My_Complaints/NewComplaints', element: <ProtectRoute><NewComplaints /></ProtectRoute>  },
      { path: '/Support', element: <ProtectRoute><Support /></ProtectRoute>  },
      
      {
        path: 'My_subscriptions',
        children: [
          { element: <ProtectRoute><SubScripe /></ProtectRoute> , index: true },
          { path: ':name/:courseId/Subject', element: <ProtectRoute><SubjectSubScription /> </ProtectRoute> },
          { path: ':name/:courseId/:subjectname/:subjectId/Videos', element: <ProtectRoute><VideosSubScription /></ProtectRoute>  },
          { path: ':name/:courseId/VideoDetails/:id', element: <ProtectRoute><VideoDetails /></ProtectRoute>  },
        ],
      },
    ]
  },
  { path: '/Quiz/:videoId', element: <ProtectRoute><FinalExam /></ProtectRoute>  },
  { path: '/ExmasDetails/:quizid', element: <ProtectRoute><ExamDetails /></ProtectRoute>  },

]);

const App = () => {

  let {setuserToken} = useContext(UserContext)
  useEffect(() => {
      if (localStorage.getItem('userToken')!==null) {
          setuserToken(localStorage.getItem('userToken'))
      }
  }, []);
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault(); // Prevent the default context menu behavior
    };

    document.addEventListener('contextmenu', handleContextMenu); // Add event listener on mount

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu); // Cleanup on unmount
    };
  }, []);
  return <>
    <ToastContainer  theme='colored'position="top-right"
    autoClose={1000}/>
      <RouterProvider router={router} />
      </>
}

export default App;
