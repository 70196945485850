import React, { useEffect, useState } from 'react';
import Style from './Subject.module.css'
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import VideosSection from '../VideosSection/VideosSection';

const Subject = () => {
  const [phonewhats, setphoneWhats] = useState(null);
  const [phonetele, setphonetele] = useState(null);
  const [pending, setpending] = useState();

  let navigate =useNavigate()

  const [loading, setloading] = useState(false);
  const notify = (mess, type) => {
    toast[type](mess);
  };
    let {courseId,name}=useParams()
    function getSubjets() {
             
        return axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/course-subject/course/${courseId}`, {
           headers: {
             Authorization: `Bearer ${localStorage.getItem('userToken')}`
           }
         });
       
     }
     let {isFetching,isLoading,isError,data}=useQuery('featuredSubject',getSubjets,{
      cacheTime:100
     })

     function Subscriptions() {
      return axios.post(`https://itc-541ea27f6158.herokuapp.com/api/v1/subscription/${courseId}`, {}, {
          headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
      })
      .then(response => {
          notify("تم أرسال طلبك للمسؤول", "warn");
          setpending(response.data.data.subscriptionStatus)
          // return response.data; // Assuming response.data contains subscription data
      })
      .catch(error => {
          // Handle error
          notify(error.response.data.message, "warn");
          console.error('Error fetching subscription data:', error);
          throw error; // Propagate the error for the caller to handle
      });
  }
      const [courseData, setCourseData] = useState(null);
      useEffect(() => {
        const fetchData = async () => {
          try {
            setloading(true)
            const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/course/${courseId}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
              }
            });
            setloading(false)
            setCourseData(response?.data.data);
          } catch (error) {
            setloading(false)
            if (error.response.status === 405) {
              setloading(false)
              navigate('/login') ;
               notify(error.response.data.message, "success");
        
            }

            console.error('Error fetching course data:', error);
            // Handle error
          }
        };
        const getPhone = async () => {
          try {
            setloading(true)
            const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/technical-support/phone-number`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
              }
            });
            setloading(false)
            setphoneWhats(response?.data?.data.whatsapp)
            setphonetele(response?.data?.data.telegram)
          } catch (error) {
            if(error.response.status==400){
            }
    
            console.error('Error fetching course data:', error);
            // Handle error
          }
        };
        getPhone()
    
        fetchData();
    
      }, []);
    return <>
    <Helmet>
          <title>الموضوعات</title>
          </Helmet>


    {isLoading && loading?<>
        <div className={`${Style.home2} w-full flex justify-center`}>
                <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

</div></>:<>
<div className={`${Style.home} bg-gray-100 min-h-screen w-full    rounded-tr-3xl    `}>
    
<div className="  flex items-center bg-white p-5  rounded-xl my-5">
<div className="flex items-center">
        <Link to={'/'} className="mr-2 text-green-500">{name}</Link>
    </div>   
     <div className="flex items-end pt-1">
        <span className='px-2'>
            <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
            </svg>
        </span>
    </div>
    <Link to={`/${name}/${courseId}/subjects`} className='font-bold text-green-600'>الموضوعات</Link>
</div>
<div className=" bg-white py-5 rounded-xl my-5 max-sm:px-5 px-10">
<div className="text-center">
        <p  className="mr-2 text-2xl font-bold text-customGreen">عن الدورة التعليمية</p>
    </div>   
    <hr  className={`${Style.hr} bg-green-500 mt-2 `}/>
   
<p className='text-xl text-gray-400 p-2 max-sm:px-0 max-sm:text-lg ' style={{letterSpacing: '1.5px',color:'#4D4C4CCC'}}>
{courseData?.courseDescription}
</p>
    </div>

            
            <div className={`  grid  gap-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  sm:grid-cols-2 `}>
            {data?.data.data.map((subject)=>{
                return <>
                <div key={subject.subjectId}>
                <Link to={`/${name}/${courseId}/${subject.subjectTitle}/${subject.subjectId}/Videos`}  className={` block  p-3 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 `}>
     
     <div className="flex items-center justify-between pt-2">
     <h5 className="mb-1 text-lg font-medium tracking-tight text-gray-900 ">{subject?.subjectTitle}</h5>

 
         </div>
         <h5 className="mb-2 text-lg tracking-tight text-green-500 ">{subject?.subjectDescription}</h5>
            <p className='text-progress_text'> عدد الفيديوهات : {subject.numberOfVideos} </p>
 </Link>
                </div>
                </>
            })}




 
 



   
  

  </div>
  {courseData?.isFree || courseData?.isSubscribed ||courseData?.subscriptionStatus=="PENDING"? <>
  
  </>:
  <>
       <div className='bg-white max-md:w-7/12 max-sm:w-12/12 text-center p-5 w-6/12 m-auto max-sm:w-10/12  rounded-xl mt-10'>
                        <button onClick={()=>document.getElementById('my_modal_2').showModal()}   className='bg-yellowCustom btn-ghost max-md:px-20 max-md:text-md  max-md:py-4 max-sm:px-16 max-sm:py-4 max-sm:text-lg   hover:bg-yellow-400 text-white p-5 px-24 text-3xl rounded-xl'>اشترك الأن</button>
                        <dialog id="my_modal_2" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg mb-4"> قم بتأكيد الطلب ثم تواصل مع المسؤول</h3>
    {phonewhats || phonetele?<>
    <div className={`grid grid-cols-1 ${Style.wwww} w-8/12 mx-auto `}>
    <Link to={`https://wa.me/+2${phonewhats}`} className='btn mb-2 hover:border-customGreen  bg-white  border-customGreen'>
        <h3 className={`text-lg ${Style.font_wha} `}>{phonewhats}</h3>
        <svg  className={Style.svgs} width="29" height="29" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_110_458)">
<path d="M3.61863 14.3742C3.61795 16.8188 4.25958 19.2058 5.47962 21.3098L3.50195 28.4983L10.8915 26.5694C12.9354 27.6771 15.2254 28.2575 17.5525 28.2577H17.5586C25.2407 28.2577 31.4942 22.0344 31.4975 14.3851C31.499 10.6785 30.0503 7.19304 27.4183 4.57077C24.7868 1.94873 21.287 0.503958 17.558 0.502266C9.87493 0.502266 3.62192 6.72524 3.61875 14.3742" fill="url(#paint0_linear_110_458)"/>
<path d="M3.12121 14.3695C3.12042 16.9021 3.78504 19.3745 5.04858 21.5537L3 29L10.6545 27.0019C12.7636 28.1467 15.1382 28.7503 17.5545 28.7512H17.5607C25.5185 28.7512 31.9966 22.304 32 14.381C32.0014 10.5412 30.5006 6.93042 27.7746 4.21421C25.0483 1.49834 21.4233 0.00157886 17.5607 0C9.60158 0 3.12438 6.44627 3.12121 14.3695ZM7.67965 21.1784L7.39384 20.7268C6.19238 18.8249 5.55823 16.6271 5.55914 14.3704C5.56174 7.78481 10.9453 2.42694 17.5653 2.42694C20.7711 2.42829 23.7839 3.67244 26.05 5.92976C28.316 8.18731 29.5629 11.1883 29.5621 14.3801C29.5591 20.9656 24.1754 26.3242 17.5607 26.3242H17.556C15.4021 26.323 13.2898 25.7472 11.4476 24.659L11.0092 24.4002L6.46686 25.5858L7.67965 21.1783V21.1784Z" fill="url(#paint1_linear_110_458)"/>
<path d="M13.9527 8.3625C13.6824 7.76445 13.398 7.75238 13.1409 7.74189C12.9304 7.73287 12.6898 7.73355 12.4495 7.73355C12.2088 7.73355 11.8179 7.82366 11.4875 8.18285C11.1567 8.54238 10.2246 9.4112 10.2246 11.1783C10.2246 12.9455 11.5175 14.6533 11.6977 14.8931C11.8782 15.1326 14.1936 18.8749 17.8608 20.3146C20.9085 21.511 21.5287 21.2731 22.1902 21.2131C22.8517 21.1533 24.3248 20.3445 24.6254 19.5058C24.9261 18.6672 24.9261 17.9483 24.836 17.7981C24.7458 17.6485 24.5052 17.5586 24.1444 17.379C23.7835 17.1994 22.0097 16.3305 21.679 16.2106C21.3483 16.0908 21.1078 16.031 20.8672 16.3907C20.6265 16.7498 19.9356 17.5586 19.7251 17.7981C19.5147 18.0382 19.3041 18.0681 18.9434 17.8885C18.5824 17.7082 17.4205 17.3294 16.0419 16.1059C14.9694 15.1539 14.2453 13.9782 14.0348 13.6185C13.8243 13.2595 14.0123 13.0648 14.1932 12.8858C14.3553 12.7249 14.5541 12.4664 14.7347 12.2568C14.9146 12.047 14.9746 11.8973 15.0949 11.6578C15.2153 11.4181 15.1551 11.2083 15.065 11.0286C14.9746 10.849 14.2735 9.07265 13.9527 8.3625Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_110_458" x="0" y="0" width="35" height="35" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="1.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_110_458"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_110_458" result="shape"/>
</filter>
<linearGradient id="paint0_linear_110_458" x1="1403.28" y1="2800.11" x2="1403.28" y2="0.502266" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FAF38"/>
<stop offset="1" stop-color="#60D669"/>
</linearGradient>
<linearGradient id="paint1_linear_110_458" x1="1453" y1="2900" x2="1453" y2="0" gradientUnits="userSpaceOnUse">
<stop stop-color="#F9F9F9"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
</defs>
</svg>

    </Link>
      <Link to={phonetele} className='btn hover:border-customGreen  bg-white  border-customGreen'>
        <h3 className={`text-lg ${Style.font_wha} `}>{phonetele}</h3>
        <svg className={Style.svgs} width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_441_26)">
<path d="M14.5 0C10.6552 0 6.96453 1.52862 4.24805 4.24691C1.52876 6.96629 0.000753252 10.6543 0 14.5C0 18.3441 1.5293 22.0348 4.24805 24.7531C6.96453 27.4714 10.6552 29 14.5 29C18.3448 29 22.0355 27.4714 24.752 24.7531C27.4707 22.0348 29 18.3441 29 14.5C29 10.6559 27.4707 6.96521 24.752 4.24691C22.0355 1.52862 18.3448 0 14.5 0Z" fill="url(#paint0_linear_441_26)"/>
<path d="M6.56279 14.347C10.7904 12.5055 13.6089 11.2914 15.0181 10.7048C19.0464 9.02985 19.8824 8.73895 20.4284 8.72909C20.5485 8.72716 20.8158 8.75684 20.9903 8.89788C21.1353 9.01682 21.1761 9.17768 21.1965 9.29063C21.2146 9.40345 21.2395 9.6606 21.2191 9.86134C21.0016 12.1541 20.0568 17.7181 19.5765 20.2862C19.3749 21.3728 18.9739 21.7371 18.5865 21.7726C17.7436 21.8501 17.1047 21.2162 16.2891 20.6817C15.0136 19.845 14.2931 19.3244 13.0538 18.5081C11.6219 17.5647 12.5508 17.0461 13.3665 16.1987C13.5794 15.9769 17.2905 12.6023 17.3608 12.2962C17.3698 12.2579 17.3789 12.1152 17.2928 12.04C17.209 11.9645 17.0843 11.9903 16.9937 12.0107C16.8646 12.0397 14.8278 13.3873 10.8765 16.0533C10.2988 16.4507 9.77544 16.6444 9.30419 16.6342C8.78763 16.6231 7.79076 16.3415 7.0499 16.1009C6.14365 15.8057 5.42091 15.6496 5.48435 15.1482C5.51607 14.8872 5.8763 14.6201 6.56279 14.347Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_441_26" x1="1450" y1="0" x2="1450" y2="2900" gradientUnits="userSpaceOnUse">
<stop stop-color="#2AABEE"/>
<stop offset="1" stop-color="#229ED9"/>
</linearGradient>
<clipPath id="clip0_441_26">
<rect width="29" height="29" fill="white"/>
</clipPath>
</defs>
</svg>


    </Link>
    </div>
    
    
    
    </>:<>
    <Link className='btn hover:border-customGreen w-8/12 bg-white  border-customGreen'>
        <h3 className='text-2xl'>لا يوجد رقم تواصل الان</h3>
  

    </Link>
  </>}
   
  <div className="modal-action">
      <form method="dialog" className='mx-auto'>
        {/* if there is a button in form, it will close the modal */}
        <button onClick={Subscriptions} className="py-2.5 px-5  text-sm font-medium text-white  bg-customGreen rounded-lg border border-gray-200 hover:bg-green-700   ">
                   تأكيد الطلب
                    </button>
                <button className="text-white ms-3 bg-red-600 hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                    الغاء الطلب
                </button>
             
              </form>
    </div>
    </div>

</dialog>

    </div>

  </>}

  
  
                    
  
  
            </div>
</>}
  

         
    </>
       
}

export default Subject;
